import React from 'react';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import ContactMe from '../images/video_call.svg';

const Header = styled.header`
  width: 100%;
  height: 800px;
  position: relative;
  padding: 1.75rem;
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    height: 600px;
  }
`;

const Hero = styled(Flex)`
  height: 100%;
  text-align: center;
  h1 {
    letter-spacing: 0.2rem;
    line-height: 4.5rem;
  }
  h3 {
    font-family: ${props => props.theme.fontFamily.body};
    margin-top: 2rem;
    font-size: 1.85rem;
    font-weight: 400;
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    h1 {
      line-height: 3.5rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    h1 {
      line-height: 2.5rem;
    }
    h3 {
      font-size: 1.3rem;
    }
  }
`;

const Wrapper = styled(Flex)`
  max-width: ${props => props.theme.maxWidth};
`;

const ServiceImage = styled.div`
  flex-basis: calc(99.9% * 4 / 8 - 3rem);
  max-width: calc(99.9% * 4 / 8 - 3rem);
  width: calc(99.9% * 4 / 8 - 3rem);
  text-align: center;
  margin-left: 3rem;
  margin-right: 3rem;
  img {
    width: 98%;
    margin-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    img {
      width: 50%;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    img {
      width: 75%;
    }
  }
`;

const ServiceText = styled.div`
  flex-basis: calc(99.9% * 4 / 8 - 3rem);
  max-width: calc(99.9% * 4 / 8 - 3rem);
  width: calc(99.9% * 4 / 8 - 3rem);
  padding-left: 3rem;
  padding-right: 3rem;
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
  ol,
  ul {
    list-style: none;
    margin-left: 0;
  }
  li:before {
    content: '－';
    padding-right: 15px;
  }
`;

const IndexPage = () => (
  <Layout>
    <Header>
      <Hero justifyContent="center" alignItems="center" flexDirection="column">
        <h1>
          I'm Ronny.
          <br />A seasoned product and engineering lead
        </h1>
        <h3>
          ...who helps teams to build great products and has been
          <br />
          working hands-on across the stack for almost two decades
        </h3>
      </Hero>
    </Header>

    <Wrapper flexDirection="column" p={4} mx="auto">
      <Flex w={1} py={3} justifyContent="space-between" flexDirection="row-reverse" flexWrap="wrap">
        <ServiceImage>
          <img src={ContactMe} alt="Let's Talk" />
        </ServiceImage>
        <ServiceText>
          <h1>Let's talk.</h1>
          <h3>
            <a href="https://linkedin.com/in/ronnyunger" target="_blank" rel="noopener noreferrer">
              linkedin.com/in/ronnyunger
            </a>
          </h3>
        </ServiceText>
      </Flex>
    </Wrapper>

    <Footer />
  </Layout>
);

export default IndexPage;
